<template>
  <div class="text-dark-blue">
    <Menu />
    <StartCard />
  </div>
</template>

<script>
import Menu from "@/components/start/base/Menu.vue";
import StartCard from "../components/start/StartCard.vue";

export default {
  components: {
    Menu,
    StartCard,
  },
};
</script>

<style lang="scss">
</style>