<template>
  <div class="start-page">
    <h1 class="mb-5 start-page-mobile text-center">
      {{ $t("welcomeText", { name: activeUser.name }) }}
    </h1>
    <div class="alert alert-info my-3" v-html="$t('toPayDescription2')"></div>

    <!-- <div class="col-12 col-md-4 col-lg-4">
        <div class="card text-center">
          <font-awesome-icon :icon="faFile" />
          <h4>{{ $t("documents") }}</h4>
        </div>
      </div>
      <div class="col-12 col-md-4 col-lg-4">
        <div class="card text-center">
          <font-awesome-icon :icon="faCreditCard" />
          <h4>{{ $t("bank") }}</h4>
        </div>
      </div> -->
    <div class="w-100">
      <div class="row justify-content-center">
        <div class="col-12 col-md-4 col-lg-4 my-2">
          <router-link to="/registrieren" class="text-dark-blue">
            <div class="card text-center">
              <font-awesome-icon :icon="faClipboard" />
              <h4>{{ $t("newTax") }}</h4>
              <p class="text-muted">
                {{ $t("newTaxDescription") }}
              </p>
            </div>
          </router-link>
        </div>
        <div class="col-12 col-md-4 col-lg-4 my-2">
          <router-link to="/payments" class="text-dark-blue">
            <div class="card text-center">
              <font-awesome-icon :icon="faCreditCard" />
              <h4>{{ $t("toPay") }}</h4>
              <p class="text-muted">
                {{ $t("toPayDescription") }}
              </p>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faFile,
  faCreditCard,
  faClipboard,
} from "@fortawesome/free-regular-svg-icons";
import { mapGetters } from "vuex";

export default {
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      faFile,
      faCreditCard,
      faClipboard,
    };
  },
  computed: {
    ...mapGetters(["activeUser"]),
  },
};
</script>

<style lang="scss">
.start-page {
  padding: 10px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../../assets/img/image-3.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  a {
    &:hover {
      color: #161640;
    }
  }

  .card {
    padding: 30px 50px;
    background-color: #f1f8fa;
    border-radius: 10px;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 3px 12px rgba(6, 11, 47, 0.06);

    h4 {
      margin-top: 30px;
    }

    svg {
      height: 60px;
    }
  }
}
@media screen and (max-width: 767px) {
  .start-page {
    display: block;
    background-image: none;
    padding-top: 80px !important;
    text-align: center;
    font-size: 26px;
    overflow-y: scroll;
  }
  .start-page-mobile {
    text-align: center;
    font-size: 26px;
  }

  .start-page {
    .card {
      margin: 0px 20px;
    }
  }
}
</style>
